<template>
  <section class="dialog">
    <spinner
      size="48"
      theme-color
    />
    <div class="dialog__title">
      Simulating ...
    </div>
    <schedule-button
      type="outline"
      @click="cancelSimulation"
    >
      Cancel
    </schedule-button>
  </section>
</template>

<script>

export default {
  name: '',
  props: {
    isInserted: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timer: null
    }
  },
  computed: {
  },
  mounted () {
    window.setTimeout(() => {
      this.$router.push({ name: 'DemoOTSimulationResult', query: { inserted: this.isInserted } })
    }, 3000)
  },
  methods: {
    cancelSimulation () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    color: var(--color-theme);
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;
    margin-top: 24px;
  }
}
</style>
