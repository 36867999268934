var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-shift"},[_c('i',{staticClass:"el-icon-time"}),_c('el-time-select',{attrs:{"placeholder":_vm.$t('schedule.base.startTime'),"clearable":false,"picker-options":{
      start: '00:00',
      step: '00:30',
      end: '24:00'
    },"prefix-icon":"null"},on:{"change":_vm.changeTime},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),_c('span',{staticClass:"conjunction"},[_vm._v("To")]),_c('el-time-select',{attrs:{"placeholder":_vm.$t('schedule.base.endTime'),"clearable":false,"picker-options":{
      start: '00:00',
      step: '00:30',
      end: '24:00',
      minTime: _vm.startTime
    },"prefix-icon":"null"},on:{"change":_vm.changeTime},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}}),_c('i',{staticClass:"el-icon-remove-outline",on:{"click":function($event){return _vm.$emit('delete')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }